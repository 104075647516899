import useFetch from "../hooks/useFetch";
import { Event } from "./Event";

export function EventsSection({ events }) {
  const currentDate = new Date();

  const { loading, error, data } = useFetch(
    "http://worldwidehauskonzert.de/wp-json/tribe/events/v1/events/"
  );

  if (loading) return <p>Loading...</p>;

  if (error) return <p>{error}</p>;

  return (
    <section id="events" className='flex justify-center w-full h-auto mb-14'>
        <div className="w-5/6 lg:w-3/5 grid grid-cols-1 gap-y-5">{data.events.map((event) => (Date.parse(event.date) < currentDate &&
          
            <Event
              date={
                event.start_date_details.day +
                "." +
                event.start_date_details.month +
                "." +
                event.start_date_details.year
              }
              time={
                event.start_date_details.hour +
                ":" +
                event.start_date_details.minutes +
                " Uhr"
              }
              location={event.venue.venue}
              city={event.venue.city}
              ticket={event.website}
            />
          )
        )}</div>
    </section>
  );
}
