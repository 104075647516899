import { useState } from "react";
import HamburgerLogo from "../assets/Hamburger_icon.svg";
import { LogoBtn } from "./LogoBtn";
import { NavButton } from "./NavBtn";

export function NavBarMobile() {
  const [isHidden, setIsHidden] = useState(true);

  const expandMenu = () => {
    isHidden ? setIsHidden(false) : setIsHidden(true);
  };

  return (
    <nav className='fixed bottom-0 left-0 pl-8 pb-8 xl:hidden'>
      {!isHidden && (
        <div id='nav-buttons' className='w-full h-full flex flex-col'>
          <NavButton onClick={expandMenu} link={"#bio"}>Bio</NavButton>
          <NavButton onClick={expandMenu} link={"#music"}>Musik</NavButton>
          <NavButton onClick={expandMenu} link={"#contact"}>Kontakt</NavButton>
          <NavButton onClick={expandMenu} link={"#events"}>Termine</NavButton>
          <NavButton>Impressum</NavButton>
          <NavButton>Datenschutz</NavButton>
        </div>
      )}
      <LogoBtn onClick={expandMenu} logo={HamburgerLogo} />
    </nav>
  );
}
