import { NavButton } from "./NavBtn";

export const ContactSection = () => {
  return (
    <section className='flex justify-center sm:mt-56 w-full font-playfair ' id="contact">
      <div className='ml-7 sm:ml-0 w-96 grid grid-cols-1 grid-rows-2 sm:grid-cols-2 sm:grid-rows-1 gap-7'>
        <div className='justify-self-start self-end sm:self-auto font-bold text-xl ml-6 sm:ml-0'>
          kontaktiere mich
        </div>
        <div>
          <div className='ml-6 mb-8 text-2xl'>
            Martin Sladek <br />
            Beispiel Straße 5 <br />
            58839 Köln{" "}
          </div>
          <a href='mailto:info@martinsladek.de'>
            <NavButton>info@martinsladek.de</NavButton>
          </a>
        </div>
      </div>
    </section>
  );
};
