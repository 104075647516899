
import { NavButton } from "./NavBtn";

export function NavBar() {

  return (
    <nav className='w-max pt-10 pb-8 px-10 fixed bottom-0 left-0 hidden xl:inline-block translate-y-5'>
      <NavButton link={"#bio"}>Bio</NavButton>
      <NavButton link={"#music"}>Musik</NavButton>
      <NavButton link={"#contact"}>Kontakt</NavButton>
      <NavButton link={"#events"}>Termine</NavButton>
    </nav>
  );
}
