import "./App.css";

import { Hero } from "../src/components/Hero.js";
import { YoutubeSection } from "./components/YoutubeSection";
import { NavBar } from "./components/NavBar";
import { NavBarMobile } from "./components/NavBarMobile";
import { Footer } from "./components/Footer";
import { SocialBar } from "./components/SocialBar";
import { EventsSection } from "./components/EventsSection";
import { BioSection } from "./components/BioSection";
import { ContactSection } from "./components/ContactSection";


function App() {

  return (
    <div
      onScroll={() => {
        console.log("hallo");
      }}
    >
      <Hero />
      <BioSection />
      <YoutubeSection />
      <EventsSection />
      <ContactSection />
      <NavBar />
      <Footer />
      <SocialBar />
      <NavBarMobile />
      
    </div>
  );
}

export default App;
