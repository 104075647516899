import backgroundImage from "../assets/background-martin.jpg";

export function Hero() {
  return (
    <section className='flex justify-center content-center w-full h-screen p-7 bg-white ' >
      <img
        src={backgroundImage}
        className='object-cover w-full  overflow-clip'
        alt='Hintergrundbild'
      />
      <h1 className="font-playfair absolute text-white  text-4xl sm:text-6xl md:text-8xl" style={{top: "55vh", left: "40vw"}}>Martin Sladek</h1>
    </section>
  );
}
