import { TicketBtn } from "./TicketBtns";

export function Event(props) {
  return (
    <>
      <div className='grid justify-center items-center grid-cols-2 grid-rows-2 md:grid-cols-4 md:grid-rows-1 gap-x-10 text-xl font-playfair'>
        <div>{props.date}</div>
        <div className='font-semibold'>{props.time}</div>
        <div>{props.location + ", " + props.city}</div>
        <div className='md:text-right'>
          <TicketBtn link={props.ticket} />
        </div>
      </div>
      <hr />
    </>
  );
}
