import YoutubeLogo from "../assets/youtube-logo.svg";
import MailLogo from "../assets/mail-icon.svg";
import InstaLogo from "../assets/instagram-logo.svg";
import TiktokLogo from "../assets/tiktok-logo.svg"

import { NavButton } from "./NavBtn";

import { LogoBtn } from "./LogoBtn";

export function SocialBar() {
  return (
    <div className='flex flex-row pb-8 fixed bottom-0 right-0 '>
      <div className="xl:inline hidden translate-y-5"><NavButton>Impressum</NavButton>
      <NavButton>Datenschutz</NavButton></div>
      <LogoBtn link="https://www.tiktok.com" logo={TiktokLogo} />
      <LogoBtn link="https://www.youtube.com" logo={YoutubeLogo} />
      <LogoBtn link="mailto:info@martinsladek.de" logo={MailLogo} />
      <LogoBtn link="https://www.instagram.com" logo={InstaLogo} />
    </div>
  );
}
