import Iframe from "react-iframe";

export function YoutubeSection() {
  return (
    <section id="music" className="flex justify-center items-center h-96 sm:h-screen">
      <Iframe
        src='https://www.youtube.com/embed/6mbBj2QFUVE'
        title='YouTube video player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowfullscreen
        className={`w-5/6 sm:w-4/5 sm:h-1/2 lg:w-2/3 lg:h-2/3`}
      />
    </section>
  );
}
