import { useState } from "react";
import Portrait from "../assets/Martin_Sladek_01_Copyright_Moritz Möller_klein.jpg";
import { NavButton } from "./NavBtn";

import useFetch from "../hooks/useFetch";

export function BioSection({ bios }) {
  const { loading, error, data } = useFetch("http://www.worldwidehauskonzert.de/wp-json/wp/v2/posts");

  const [isShort, setIsShort] = useState(true);

  const toggleBio = () => {
    if (isShort) setIsShort(false);
    else setIsShort(true);
  };

  if (loading) return <p>Loading...</p>;

  if (error) return <p>{error}</p>;

  return (
    <section
      id='bio'
      className="w-full flex justify-center items-center"
    >
      <div className="flex font-playfair w-5/6 lg:w-2/3 h-max mt-56 justify-center items-start flex-col md:flex-row ">
        <img
          src={Portrait}
          alt='Portrait von Martin Sladek'
          className='w-full md:w-96 md:pr-14 grow-1 float-left'
        />
        <div className='flex justify-start flex-col items-center'>
          <p className='text-lg pb-10 grow mt-10 md:mt-0' dangerouslySetInnerHTML={{__html: isShort
              ? data[1].content.rendered
              : data[0].content.rendered}}>
          </p>
          <NavButton onClick={toggleBio}>
            {isShort ? "lange " : "kurze "}Bio
          </NavButton>
        </div>
      </div>
    </section>
  );
}
